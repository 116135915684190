import React from "react";
import { Link } from "gatsby";
import LazyLoad from "react-lazyload";

import * as Constants from "../../lib/constants";
import * as styles from "./CourseList.module.css";

const CourseList = ({ courses }) => {
  return (
    <div className={styles.container}>
      {courses.map((course) => {
        const flagKeyLangToLearn = Constants.FLAG_KEYS[course.langToLearn];
        const flagKeyLangUser = Constants.FLAG_KEYS[course.langUser];
        return (
          <div key={course.id} className={styles.courseItem}>
            <Link title={course.name} to={course.coursePath}>
              <div>
                <div
                  className={styles.courseImageContainer}
                  style={{
                    backgroundColor: course.backgroundColor,
                  }}
                >
                  {course.imageURL ? (
                    <LazyLoad
                      style={{
                        height: 180,
                        width: 180,
                      }}
                      height={180}
                      once
                    >
                      <img
                        className={styles.courseImage}
                        src={course.imageURL}
                        alt={`${course.name} logo`}
                        height={180}
                        width={180}
                      />
                    </LazyLoad>
                  ) : (
                    <div className={styles.courseImage} />
                  )}
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      display: "flex",
                      padding: "10px",
                      backgroundColor: "rgba(0,0,0,0.1)",
                    }}
                  >
                    <div style={{ marginRight: 10, height: 20, width: 20 }}>
                      <img
                        src={`/flags/${flagKeyLangToLearn}.svg`}
                        alt={flagKeyLangToLearn}
                        width={20}
                        height={20}
                      />
                    </div>
                    <div style={{ height: 20, width: 20 }}>
                      <img
                        src={`/flags/${flagKeyLangUser}.svg`}
                        alt={flagKeyLangUser}
                        width={20}
                        height={20}
                      />
                    </div>
                  </div>
                </div>
                <h2 className={styles.courseName}>{course.name}</h2>
                <div>
                  <span className={styles.textItemCount}>{course.wordCountText}</span>
                </div>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default CourseList;
