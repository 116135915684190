import React from "react";

import Layout from "../../components/Layout";
import Breadcrumb from "../../components/Breadcrumb";
import * as Constants from "../../lib/constants";
import CourseList from "../../components/CourseList";

const CoursesByLang = ({ pageContext: { pageData, coursesByLang, pagePath }, path }) => {
  const { langUser, langToLearn } = pageData;

  return (
    <Layout
      title={pageData.title}
      metaDesc={pageData.metaDesc}
      langUser={langUser}
      ogUrlPath={pagePath}
      canonicalPath={pagePath}
    >
      <Breadcrumb
        items={[
          { label: pageData.rootBreadcrumbLabel, to: `/courses?l=${langUser}` },
          { label: pageData.title },
        ]}
      />
      <main>
        <h1>{pageData.title}</h1>
        <p>{pageData.pageDesc}</p>
        <div>
          <CourseList courses={coursesByLang} />
        </div>
      </main>
    </Layout>
  );
};

export default CoursesByLang;
